$(document).ready(function () {
	animationLoader();
	// header
	mappingMenu();
	// swiper
	swiperInit();
	//
	header.init();
	setBackgroundElement();
	// toggleHoTro();
	toggleHoTro2();
	listCountry();
	appendBreadcrumb();
	ButtonToTop();
	// findAInContent();
	ChangeTextForm();
	Fancybox.bind("[data-fancybox]", {
		// parentEl: document.forms[0],
		click: false,
		dragToClose: false,
	});
	ScrollToTab();
	checkScrollShow();
	passValueCountryForm();
	getColorTheme();
	checkCountProductHome();
	selectFlag();
});
function checkCountProductHome() {
	if (!$('.home-1').length) return;
	let countProduct = $('.home-1 .item-category').length / 2 + 1
	$("body").get(0).style.setProperty("--countProduct", countProduct);
	//
	$('.home-1 .item-category').each(function (index) {
		if (index < countProduct - 1) {
			$(this).addClass('odd')
		} else {
			$(this).addClass('even')
		}
	})
}
function selectFlag() {
	const dataFlag = [];
	$('.form-style-1 .list-country ul li').each(function () {
		let text = $(this).data('tile')
		let id = $(this).data('flag')
		let dataGuid = $(this).data('guid')
		dataFlag.push({
			id: id,
			text: text,
			'guid': dataGuid,
		})
		$(this).remove()
	})

	console.log(dataFlag);
	function formatCountry(country) {
		if (!country.id) { return country.text; }
		var $country = $(
			`<img src="${country.id}" alt="" />`
			+
			'<span class="flag-text">' + country.text + "</span>"
		);
		return $country;
	};

	function formatState(state) {
		console.log(state.guid)
		$(".list-country input[type='hidden']").val(state.guid)
		if (!state.id) {
			return state.text;
		}
		var baseUrl = "/Data/Sites/1/Country/";
		var $state = $(
			'<span><img class="img-flag" /> <span></span></span>'
		);
		// Use .text() instead of HTML string concatenation to avoid script injection issues
		$state.find("span").text(state.text);
		$state.find("img").attr("src", state.id);
		return $state;
	};
	// Sort select
	function matchStart(params, data) {
		if ($.trim(params.term) === '') {
			return data;
		}
		if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) == 0) {
			var modifiedData = $.extend({}, data, true);
			modifiedData.text += '';
			// You can return modified objects from here
			// This includes matching the `children` how you want in nested data sets
			return modifiedData;
		}
		// console.log(params.term)
		// if ($.trim(params.term) === '') {
		// 	return data;
		// }
		return null;
	}
	$(".input-country select").select2({
		placeholder: "Select a country",
		templateSelection: formatState,
		templateResult: formatCountry,
		matcher: matchStart,
		data: dataFlag
	});
	$('.popup-with-form').magnificPopup({
		type: 'inline',
		preloader: false,
		focus: '#name',
		// When elemened is focused, some mobile browsers in some cases zoom in
		// It looks not nice, so we disable it:
		prependTo: document.forms[0],
		callbacks: {
			beforeOpen: function () {
				if ($(window).width() < 700) {
					this.st.focus = false;
				} else {
					this.st.focus = '#name';
				}
			}
		}
	});
}
function getColorTheme() {
	if (!$(".color-get").length) return;
	let colorGet = $(".color-get").attr("data-color");
	if (colorGet != "") {
		console.log(
			"🚀 ~ file: main.js ~ line 28 ~ getColorTheme ~ colorGet",
			colorGet
		);
		$("body").get(0).style.setProperty("--color", colorGet);
		$("body").attr("data-theme", "change");
	}
}
function passValueCountryForm() {
	if (!$(".input-country").length) return;
	$(".list-country li").click(function () {
		let dataId = $(this).attr("data-guid");
		console.log("🚀 ~ file: main.js ~ line 28 ~ dataId", dataId);
		$(".value-country input").val(dataId);
		console.log($(".value-country input").value);
	});
}
function checkScrollShow() {
	$(window).scroll(function () {
		if (
			$(window).scrollTop() + $(window).height() >
			$(document).height() - 500
		) {
			$(".button-top").addClass("isShow");
		} else {
			$(".button-top").removeClass("isShow");
		}
	});
}
const ScrollToTab = () => {
	$(".tab-product-list ul").scrollTo("li.active");
};
/*==================== Change Text Register ====================*/
function ChangeTextForm() {
	// $('.form-style-1 input[type="submit"]').attr("value", "Register");
}
/*==================== Function generate file download ====================*/
function findAInContent() {
	if (!$(".product-tab").length) return;
	$(".product-tab").each(function () {
		let downloadRef = $(this).find(".content a:first-child");
		let linkDownload = downloadRef.attr("href");
		downloadRef.remove();
		console.log(downloadRef.length);
		$(this).find(".btn-download").attr("href", linkDownload);
		if (downloadRef.length < 1) {
			$(this).find(".btn-download").remove();
		} else {
			$(this).find(".btn-download").addClass("show");
		}
	});
}
/*====================  ====================*/
function ButtonToTop() {
	$(".button-top").click(function () {
		$("html, body").animate({ scrollTop: 0 }, 800);
	});
}
/*==================== appendBreadcrumb ====================*/
function appendBreadcrumb() {
	$("ol.breadcrumb").appendTo(".banner__pages .breadcrumb-append");
}
/*==================== ListCountry ====================*/
function listCountry() {
	let textCountry = $(".value-country");
	let itemCountry = $(".list-country li");
	itemCountry.click(function () {
		let src = $(this).find("img").attr("src");
		let text = $(this).find("span").text();
		textCountry.find("span").text(text);
		textCountry.find("img").length
			? textCountry.find("img").attr("src", src)
			: textCountry.prepend(`<img src="${src}"/>`);
	});
}
/*==================== Toggle ====================*/
function toggleHoTro2() {
	$(".fill-item").click(function (e) {
		$(this).toggleClass("fill-item--active");
	});
	$(".toggle-faq .title-main").click(function () {
		$(this)
			.next()
			.slideToggle()
			.parent()
			.siblings()
			.find(".content")
			.slideUp();
	});
	$(document).on("click", function (event) {
		var $trigger = $(".fill-list");
		if ($trigger !== event.target && !$trigger.has(event.target).length) {
			setTimeout(() => {
				$(".fill-item").removeClass("fill-item--active");
			}, 100);
		}
	});
	$(".toggle-faq .title-small").click(function () {
		$(this).next().slideToggle();
	});
}
/*==================== SetBackgroundElement ====================*/
function setBackgroundElement() {
	$("[setBackground]").each(function () {
		var background = $(this).attr("setBackground");
		$(this).css({
			"background-size": "cover",
			"background-position": "center center",
			"background-image": "url(" + background + ")",
		});
	});
}
/*==================== Mapping Menu ====================*/
function mappingMenu() {
	let ww = $(window).width();
	if (ww < 1280) {
		$("header .language-wrap").appendTo("#menu-mobile");
		$("header .list-main-menu").appendTo("#menu-mobile");
	}
}
/*==================== Animation loader  ====================*/
function animationLoader(e) {
	$("body").addClass("loaded");
}

/*==================== Append Click Scroll Tab ====================*/
function clickScrollTab() {
	$(".wrap-tab-link ul").scrollTo("li.active", 800);
	let numberTab = $(".tabslet-tab li").length;
	let countNumber = 1;
	if (numberTab > 2) {
		$(".tabslet-tab").wrap('<div class="number-tab-wrap"></div>');
		$(".number-tab-wrap").append(
			'<div class="icon-next"><div class="next"><em class="material-icons">navigate_next</em></div></div>'
		);
		$(".icon-next").click(function () {
			if (countNumber < numberTab - 1) {
				$(this)
					.parent()
					.find(".tabslet-tab")
					.scrollTo("li:eq(" + countNumber + ")", 800);
				countNumber++;
			} else {
				countNumber = 1;
				$(this).parent().find(".tabslet-tab").scrollTo("li:eq(0)", 800);
			}
		});
	}
}
/*==================== JS Other ====================*/
function height(el) {
	var height = 0;
	$(el).each(function () {
		var thisHeight = $(this).height();
		if (thisHeight > height) {
			height = thisHeight;
		}
		setTimeout(() => {
			$(el).height(height);
		}, 100);
	});
}
const deviceType = () => {
	const ua = navigator.userAgent;
	if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
		return "tablet";
	} else if (
		/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
			ua
		)
	) {
		return "mobile";
	}
	return "desktop";
};
let header = {
	hambuger: function () {
		$(".hambuger").click(function () {
			$(this).toggleClass("isOpened");
			$("#menu-mobile").toggleClass("isOpened");
		});
	},
	scrollActive: function () {
		let height = $("header").height();
		if ($(window).scrollTop() > height) {
			$("header").addClass("active");
		} else {
			$("header").removeClass("active");
		}
	},
	menuChild: function () {
		if ($(window).width() < 1280) {
			let menuMobile = $("#menu-mobile");
			$("#menu-mobile .list-main-menu li.dropdown>a").click(function () {
				$(this).toggleClass("isDropdowned");
				$(this).next(".list-menu-dropdown").slideToggle();
				return false;
			});
		}
	},
	search: function () {
		$(".search").on("click", function () {
			if (deviceType() != "desktop") {
				window.location.replace("/Product/SearchResults.aspx");
			} else {
				$(this).toggleClass("active");
				$(".wrap-search-main").toggleClass("active");
				$(".tagEUP").toggleClass("isUp");
			}
		});
		$(".wrap-search-main .close").click(function () {
			$(".wrap-search-main").removeClass("active");
		});
		// Trigger click with 2 button
		// $(".wrap-search-main input[type='text']").on(
		// 	"keypress keydown",
		// 	function (n) {
		// 		if (n.keyCode == 13) {
		// 			$(".wrap-search-main .searchbox button").trigger("click");
		// 		}
		// 	}
		// );
	},

	init: function () {
		header.hambuger();
		header.menuChild();
		header.search();
	},
};
/*==================== Swiper ====================*/
function swiperInit() {
	var bannerHome = new Swiper(".banner-home .swiper", {
		grabCursor: true,
		effect: "creative",
		autoplay: {
			delay: 5000,
		},
		loop: true,
		speed: 1000,
		creativeEffect: {
			prev: {
				shadow: true,
				translate: ["-20%", 0, -1],
			},
			next: {
				translate: ["100%", 0, 0],
			},
		},
		pagination: {
			el: ".swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});
	var About3 = new Swiper(".about-3 .swiper", {
		slidesPerView: 1.1,
		autoplay: {
			delay: 3000,
		},
		spaceBetween: 0,
		breakpoints: {
			768: {
				slidesPerView: 3,
			},
			1230: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 4,
			},
		},
	});
	var productApplication = new Swiper(
		".page-product-list.application .swiper",
		{
			slidesPerView: 1.3,
			spaceBetween: 30,
			navigation: {
				nextEl: ".page-product-list.application .btn-next",
				prevEl: ".page-product-list.application .btn-prev",
			},
			pagination: {
				el: ".page-product-list.application .swiper-pagination",
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1280: {
					slidesPerView: 3,
				},
			},
		}
	);
	$(".item-application-main").each(function (index) {
		let name = `item-slide-${index}`;
		$(this).addClass(name);
		new Swiper(`.${name} .application-slide .swiper`, {
			spaceBetween: 20,
			slidesPerView: 2,
			autoplay: {
				delay: 5000,
			},
			observer: true,
			observeParents: true,
			grid: {
				rows: 2,
				fill: "row",
			},
			navigation: {
				nextEl: `.${name} .application-slide .btn-next`,
				prevEl: `.${name} .application-slide .btn-prev`,
			},
		});
		new Swiper(`.${name} .application-text .swiper`, {
			spaceBetween: 10,
			slidesPerView: 1,
			observer: true,
			autoplay: {
				delay: 5000,
			},
			observeParents: true,
			breakpoints: {
				768: {
					spaceBetween: 20,
				},
			},
			pagination: {
				el: `.${name} .application-text .swiper-pagination`,
				type: 'bullets',
				clickable: true,
			},
			navigation: {
				nextEl: `.${name} .application-text .btn-next`,
				prevEl: `.${name} .application-text .btn-prev`,
			},
		});
	});
	// Tin tuc detail
	var newDetail = new Swiper(".tt-detail.slide .swiper", {
		autoplay: {
			delay: 5000,
		},
		speed: 300,
		slidesPerView: 1,
		spaceBetween: 15,
		breakpoints: {
			320: {
				slidesPerView: 2,
				spaceBetween: 15,
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 15,
			},
		},
	});
}

function toggleHoTro() {
	var fillItem = $(".fill-item");
	var fillOption = fillItem.find(".drop-down-item");
	var item = $(".item");
	var itemChild = $(".item--child");
	item.on("click", function () {
		$(this).addClass("item--active").siblings().removeClass("item--active");
	});
	fillItem.on("click", function () {
		fillItem.addClass("fill-item--active");
	});
	fillOption.on("click", function () {
		var text = $(this).find("a").text();
		$(".fill-item > a").text(text);
		setTimeout(() => {
			fillItem.removeClass("fill-item--active");
		}, 100);
	});
	$(document).on("click", function (event) {
		var $trigger = $(".fill-list");
		if ($trigger !== event.target && !$trigger.has(event.target).length) {
			setTimeout(() => {
				fillItem.removeClass("fill-item--active");
			}, 100);
		}
	});
	item.eq(0).addClass("item--active");
}

$(document).on("scroll", function () {
	header.scrollActive();
});
